import { Page } from "@app/site";
import { Spacer } from "@app/ui";
import { SEO } from "@app/ui/seo";
import { PageProps } from "gatsby";
import React from "react";

function Terms({}: PageProps) {
  // const { height } = useWindowSize();
  return (
    <Page>
      <SEO title="DeckedLink - Terms of service" />
      <Spacer h={"500px"} />
      <iframe
        style={{
          height: "100%",
          minHeight: "600px",
          width: "100%",
          position: "fixed",
          top: 0,
          left: 0,
        }}
        src={process.env.GATSBY_TERMS_PAGE_LINK}
        loading="eager"
      ></iframe>
    </Page>
  );
}

export default Terms;
